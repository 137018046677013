<template>
  <no-ssr>
    <div ref="productSwiper" class="product-swiper-column-2" @touchmove.stop>
      <!-- <GtSwiper
        v-if="dataList && dataList.length"
        ref="swiper"
        :data="newDataList"
        :swiper-option="swiperOption"
        :class="{ 'product-swiper-column-2-ad': advImgUrl }"
      > -->
      <!-- <div slot="default" slot-scope="{ data, index }" class="swiper-detail"> -->
      <div v-for="(data, index) in newDataList" :key="index" class="swiper-detail">
        <div v-if="data.type === 'ad'">
          <div v-if="advImgUrl" class="ad-img">
            <nuxt-link
              :to="advImgJumpLink"
              target="_blank"
              :module_name="operationData.module_name"
              :module_rank="operationData.module_rank"
              :operation_name="operationData.operation_name"
              :operation_id="operationData.operation_id"
              :operation_rank="operationData.operation_rank"
              :destination_url="advImgJumpLink"
              event_name="operationClick"
              click_type="图片"
            >
              <UAImage :src="advImgUrl + '?x-oss-process=image/format,webp'" class="ad-img-img" />
            </nuxt-link>
          </div>
        </div>
        <div v-else class="product-detail">
          <!-- <nuxt-link
                :to="{ name: 'pdp', params: { code: data.productNo } }"
                target="_blank"
                :module_name="data.operationData.module_name"
                :module_rank="data.operationData.module_rank"
                :operation_name="data.operationData.operation_name"
                :operation_id="data.operationData.operation_id"
                :operation_rank="data.operationData.operation_rank"
                :destination_url="data.operationData.destination_url"
                click_type="商品"
                :expose_type="index > slidesPerView - 1 ? '手动曝光' : '手动滑动'"
                :product_rank="data.operationData.product_rank"
                :primary_category="data.operationData.primary_category"
                :secondary_category="data.operationData.secondary_category"
                :third_category="data.operationData.third_category"
                :activity_attribute="data.operationData.activity_attribute"
                :gender="data.operationData.gender"
                :spu_id="data.operationData.spu_id"
                :spu_name="data.operationData.spu_name"
                :sales_price="data.operationData.sales_price"
                :list_price="data.operationData.list_price"
                class="operation-img-link"
              > -->
          <Item v-if="data.length" :data="data[0]"></Item>
          <Item v-if="data.length > 1" :data="data[1]"></Item>
          <!-- </nuxt-link> -->
        </div>
      </div>
      <!--  <div slot="pagination"></div>
      </GtSwiper> -->
    </div>
  </no-ssr>
</template>

<script>
import { componentsCommon } from '@speedshop/template';
import ossImage from '@/mixins/ossImage';
import Item from '@/components/PLP/lpItem.vue';
import UAImage from '@/components/UAImage/index.vue';

export default {
  components: { UAImage, Item },
  mixins: [componentsCommon, ossImage],
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    operationData: {},
    slidesPerView: {
      default: 1,
      type: Number
    },
    advImgUrl: {},
    advImgJumpLink: {},
    adOccupiesColumns: {}
  },
  data() {
    return {
      swiperOption: {
        observer: true,
        observerParents: true,
        slidesPerView: 3,
        slidesPerGroup: 2,
        updateOnWindowResize: true,
        spaceBetween: 12,
        updateOnImagesReady: true,
        uniqueNavElements: false,
        on: {
          imagesReady() {
            const mySwiper = this;
            function loopSetAdWidth(mySwiper) {
              const img = mySwiper.el.querySelector('.ad-img-img');
              if (!img) {
                setTimeout(() => {
                  loopSetAdWidth(mySwiper);
                }, 500);

                return;
              }
              const nWidth = img.naturalWidth;
              const nHeight = img.naturalHeight;
              const height = mySwiper?.el?.clientHeight;
              const width = (nWidth / nHeight) * height;

              mySwiper.slides[0].style.width = width + 'px';
              mySwiper.params.slidesPerView = 'auto';
              mySwiper.updateSize();
              mySwiper.update();
            }
            setTimeout(() => {
              loopSetAdWidth(mySwiper);
            }, 100);
          }
        }
      },
      scrollLeft: 0
    };
  },
  computed: {
    newDataList() {
      const data = this.dataList.map(item => {
        return {
          ...item,
          picture: (item.mediaLinkList && item.mediaLinkList.length && item.mediaLinkList[0]) || ''
        };
      });
      // 如果有广告图加2个占位的列
      let merge = [];
      const res = [];
      data.forEach((item, index) => {
        if (merge.length < 2) {
          merge.push(item);
        }
        if (merge.length === 2 || index === data.length - 1) {
          res.push(merge);
          merge = [];
        }
        return res;
      });

      return this.advImgUrl ? [{ operationData: {}, type: 'ad' }, ...res] : res;
    }
  },
  beforeDestroy() {
    this.observer?.disconnect?.();
  },
  mounted() {
    this.$nextTick(() => {});
  },
  methods: {
    onTouchmove(e) {
      const scrollDic = this.$refs.productSwiper.scrollWidth - this.$refs.productSwiper.offsetWidth;
      if (this.scrollLeft < scrollDic) {
        e.stopPropagation();
        return;
      }
      this.scrollLeft = this.$refs.productSwiper.scrollLeft;
    },
    onTouchend() {
      this.scrollLeft = 0;
    }
  }
};
</script>

<style lang="scss">
//
.page-layout_mobile {
  .widget-widget-product-swiper-with-2-columns {
    // padding-left: 20px;
    .sp-swiper-container {
      overflow: initial !important;
    }
  }
  .widget-widget-product-swiper-with-2-columns {
    padding-right: 0px !important;

    .product-swiper-column-2 {
      padding-bottom: 0px;
      border-bottom: 0px;
      display: flex;
      //  padding-left: 20px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      gap: 12px;
      .product-detail {
        width: 100px;
      }
      .ad-img {
        width: 196px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .sp-swiper-container {
        width: calc(100vw - 50px);
        min-width: calc(100vw - 50px);
        // width: 100%;
        // max-width: 100%;
        > .swiper-wrapper {
          background: #fff;
          align-items: stretch;
          // flex-direction: column;
          // flex-wrap: wrap;
        }
      }
      .product-swiper-column-2-ad {
        .swiper-slide {
          height: 100%;
          .swiper-detail {
            height: 100%;
            > div {
              width: 100%;
              height: 100%;
              .ad-img {
                height: 100%;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }

          .page-plp_product__lp-item {
            img {
              aspect-ratio: 1/1.25;
            }
            &:last-child {
              margin-bottom: 0px;
            }
          }
        }
      }
      .sp-plp-card__color {
        height: 24px !important;
        padding: 0;
        margin: 0;
        pointer-events: none;
        opacity: 0;
      }
      .swiper-slide {
      }
      .page-plp_product__lp-item {
        padding-bottom: 0px !important;
        margin-bottom: 12px;
      }
    }
  }
}
</style>
