<template>
  <div
    ref="productSwiper"
    class="product-swiper swiper-j"
    :class="[isMobile ? `mb-swiper-slider-${slidesPerView}` : `pc-swiper-slider-${slidesPerView}`]"
  >
    <GtSwiper ref="swiper" :data="newDataList" :swiper-option="swiperOption">
      <div slot="default" slot-scope="{ data, index }" class="swiper-detail">
        <div v-if="data.type === 'img'">
          <nuxt-link
            :to="data.link"
            target="_blank"
            :module_name="operationData.module_name"
            :module_rank="operationData.module_rank"
            :operation_name="operationData.operation_name"
            :operation_id="operationData.operation_id"
            :operation_rank="operationData.operation_rank"
            :destination_url="data.link"
            event_name="operationClick"
            click_type="图片"
          >
            <UAImage :src="data.picture + '?x-oss-process=image/format,webp'" style="width: 100%" />
          </nuxt-link>
        </div>
        <div v-else>
          <div>
            <!-- <nuxt-link
                :to="{ name: 'pdp', params: { code: data.productNo } }"
                target="_blank"
                :module_name="data.operationData.module_name"
                :module_rank="data.operationData.module_rank"
                :operation_name="data.operationData.operation_name"
                :operation_id="data.operationData.operation_id"
                :operation_rank="data.operationData.operation_rank"
                :destination_url="data.operationData.destination_url"
                click_type="商品"
                :expose_type="index > slidesPerView - 1 ? '手动曝光' : '手动滑动'"
                :product_rank="data.operationData.product_rank"
                :primary_category="data.operationData.primary_category"
                :secondary_category="data.operationData.secondary_category"
                :third_category="data.operationData.third_category"
                :activity_attribute="data.operationData.activity_attribute"
                :gender="data.operationData.gender"
                :spu_id="data.operationData.spu_id"
                :spu_name="data.operationData.spu_name"
                :sales_price="data.operationData.sales_price"
                :list_price="data.operationData.list_price"
                class="operation-img-link"
              > -->
            <Item :data="data"></Item>
            <!-- </nuxt-link> -->
          </div>
        </div>
      </div>
      <template slot="pagination">
        <div v-if="!isMobile">
          <div ref="nextLeft" class="swiperj-button-next swiperj-button-next-product">
            <i class="iconfont icon-a-24-2px-arrowright"></i>
          </div>
          <div ref="prevLeft" class="swiperj-button-prev swiperj-button-prev-product">
            <i class="iconfont icon-a-24-2px-arrowleft"></i>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <div v-else></div>
      </template>
    </GtSwiper>
  </div>
</template>

<script>
import { GtSwiper, componentsCommon } from '@speedshop/template';
import ossImage from '@/mixins/ossImage';
import Item from '@/components/PLP/lpItem.vue';
import UAImage from '@/components/UAImage/index.vue';

export default {
  components: { GtSwiper, UAImage, Item },
  mixins: [componentsCommon, ossImage],
  props: {
    dataList: {
      type: Array,
      default: () => []
    },
    operationData: {},
    slidesPerView: {
      default: 1,
      type: Number
    },
    advImgUrl: {},
    advImgJumpLink: {},
    adOccupiesColumns: {}
  },
  data() {
    return {};
  },
  computed: {
    swiperOption() {
      const spaceBetween =
        Number(this.slidesPerView) === 1
          ? 0
          : Number(this.slidesPerView) === 2 && !this.isMobile
          ? 24
          : this.isMobile
          ? 12
          : 48;
      const slidesPerView = this.isMobile
        ? Number(this.slidesPerView) + 0.3
        : Number(this.slidesPerView);
      return {
        observer: true, // 开启动态检查器，监测swiper和slide
        observeParents: true, // 监测Swiper 的祖/父元素
        // updateOnWindowResize: true,
        spaceBetween,
        breakpoints: {
          1441: {
            spaceBetween: spaceBetween === 24 ? 48 : spaceBetween,
            slidesPerView
          }
        },
        slidesPerView,
        slidesPerGroup: Number(this.slidesPerView),
        uniqueNavElements: true,
        // 设置点击箭头
        navigation: {
          nextEl: '.swiperj-button-next-product',
          prevEl: '.swiperj-button-prev-product'
        }
        // on: {
        //   observerUpdate() {
        //     console.log('监测到DOM变化，更新Swiper');
        //   }
        // }
      };
    },
    newDataList() {
      const data = this.dataList.map(item => {
        return {
          ...item,
          picture: (item.mediaLinkList && item.mediaLinkList.length && item.mediaLinkList[0]) || ''
        };
      });
      if (this.advImgUrl) {
        return [
          {
            type: 'img',
            picture: this.advImgUrl,
            link: this.advImgJumpLink
          },
          ...data
        ];
      } else {
        return data;
      }
    }
  },
  beforeDestroy() {
    this.observer?.disconnect?.();
  },
  mounted() {
    this.$nextTick(() => {
      // this.setLoop();
      setTimeout(() => {
        const swiper = this.$refs.swiper?.swiper;
        if (!swiper) return;
        swiper && swiper.update();
      }, 1000);
    });
  },
  methods: {
    initExposeTrack() {
      const container = this.$refs.productSwiper;
      if (!container) return;
      const animatedElements = container.querySelectorAll('.product-swiper .operation-img-link');
      const observer = new IntersectionObserver(entries => {
        entries.forEach((entry, index) => {
          if (entry.intersectionRatio <= 0) return;
          //
          const target = entry.target;
          const attributes = target.attributes;
          const trackData = {};
          for (let i = 0; i < attributes.length; i++) {
            if (
              [
                'expose_type',
                'click_type',
                'product_rank',
                'primary_category',
                'secondary_category',
                'third_category',
                'activity_attribute',
                'gender',
                'spu_id',
                'spu_name',
                'sales_price',
                'list_price',
                'destination_url',
                'module_name',
                'module_rank',
                'operation_name',
                'operation_rank'
              ].includes(attributes[i].name)
            ) {
              trackData[attributes[i].name] = attributes[i].value;
            }
          }
          this.$trackHelp.track('operationExpose', {
            ...trackData
          });
          observer.unobserve(entry.target);
        });
      });
      this.observer = observer;

      // 观察所有带有class "animated-element" 的元素
      [...animatedElements].forEach((element, index) => {
        // this.observer.observe(element);
      });
    },
    setLoop() {
      // curry 系列 pc 端轮播loop
      if (!this.isMobile && this.slidesPerView === 1) {
        setTimeout(() => {
          const swiper = this.$refs.swiper?.swiper;
          if (!swiper) return;
          swiper && swiper.params && (swiper.params.loop = true);
          swiper && (swiper.params.loopCreate = true);
          swiper && swiper.update();
          swiper && swiper.loopCreate();
          swiper && swiper.update();
          swiper && swiper.updateSize();
          swiper && swiper.updateSlides();
          swiper && swiper.slideTo(1);
        }, 1000);
      }
    }
  }
};
</script>

<style lang="scss">
//

.product-swiper.swiper-j {
  padding-bottom: 0px;
  border-bottom: 0px;
  .sp-plp-card__color {
    height: 24px !important;
    padding: 0;
    margin: 0;
    pointer-events: none;
    opacity: 0;
  }
  .page-plp_product__lp-item {
    margin-bottom: 0px;
    img {
      pointer-events: none;
    }
  }
}
.page-layout_mobile {
  .product-swiper.swiper-j {
    overflow: visible;
  }
  .swiperj-button-prev {
    display: none;
  }
  .swiperj-button-next {
    display: none;
  }
}
.page-layout_mobile
  .widget-widget-product-swiper-with-bg
  .page-plp_product__lp-item
  .sp-plp-card__name
  .product-name {
  padding-left: 8px;
  padding-right: 8px;
}
.page-layout_mobile .widget-widget-product-swiper-with-bg .page-plp_product__lp-item {
  .sp-plp-card__price,
  .sp-lp-card__final-price {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    height: auto;
  }
}
</style>
