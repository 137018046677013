<template>
  <div class="ua-personal-center__join-us">
    <ul class="join-us-channel">
      <li v-for="(item, i) in joinUsList" :key="i" @blur="handleBlur">
        <div class="ua-personal-center__image-wrapper">
          <UAImage :src="item.img" alt="关注UA" />
        </div>
        <div class="ua-personal-center__content">
          <UAImage
            :src="item.QrCode"
            :class="{
              qrcode: true,
              show: curId === item.id
            }"
            alt="关注UA"
            style="width: 100%"
          />
          <p class="type">{{ item.type }}</p>
          <p class="desc">{{ item.p }}</p>
          <el-popover
            popper-class="ua-personal-center__qrcode-wrapper"
            placement="top"
            width="400"
            trigger="hover"
          >
            <UAImage style="width: 440px; height: 173px" :src="item.QrCode"></UAImage>
            <GtButton
              slot="reference"
              type="plain"
              size="small"
              @click="buttonClick({ id: item.id })"
            >
              {{ item.buttonText }}
            </GtButton>
          </el-popover>
        </div>
      </li>
    </ul>
    <div v-if="showService" class="join-us-customer-service">
      <p>如您有任何问题，可咨询我们的客户服务部门</p>
      <p>
        <span>咨询热线：4008-206-528</span>
        <span>服务时间：周一至周日 09:00-21:00</span>
        <span>邮箱地址：service@underarmour.cn</span>
      </p>
    </div>
  </div>
</template>

<script>
import { GtButton } from '@speedshop/template';
import UAImage from '@/components/UAImage/index.vue';

export default {
  name: 'PersonalCenterJoinUs',
  components: {
    UAImage,
    GtButton
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    showService: {
      type: Boolean,
      default: true
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      curId: ''
    };
  },
  computed: {
    joinUsList() {
      return [
        {
          id: '1',
          img: require('@/assets/image/account/0.png'),
          QrCode:
            (this.data && this.data.ecMpMiniAppUrl) || require('@/assets/image/account/0-sun.png'),
          type: '安德玛官方商城',
          p: '微信小程序快捷购物',
          buttonText: '即刻体验'
        },
        {
          id: '2',
          img: require('@/assets/image/account/1.png'),
          QrCode:
            (this.data && this.data.crmMiniAppUrl) || require('@/assets/image/account/1-sun.png'),
          type: '安德玛运动家俱乐部',
          p: '解锁更多会员专属福利',
          buttonText: '即刻加入'
        },
        {
          id: '3',
          img: require('@/assets/image/account/2.png'),
          QrCode:
            (this.data && this.data.associationUrl) || require('@/assets/image/account/2-sun.png'),
          type: 'UA官方社群',
          p: '种草官方社群',
          buttonText: '即刻加入'
        }
      ];
    }
  },
  mounted() {
    window.addEventListener('click', e => {
      this.curId = '';
    });
  },
  methods: {
    buttonClick(params) {
      const { id } = params;
      this.curId = id;
    },
    handleBlur() {
      this.curId = '';
    }
  }
};
</script>

<style lang="scss">
.ua-personal-center__qrcode-wrapper.el-popper {
  background: transparent;
  border: none;
  padding: 0 !important;
  box-shadow: none;
  margin-bottom: -20px !important;
  .gt-image-container {
    background-color: transparent;
  }
  &[x-placement^='top'],
  &[x-placement^='bottom'] {
    .popper__arrow {
      display: none;
    }
  }
}
.ua-personal-center__join-us {
  padding-top: 32px;
  ul {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    li {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
      .gt-image-container {
        width: 100%;
        height: auto;
      }
    }
  }
  .ua-personal-center {
    &__content {
      padding-top: 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .qrcode {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        &.show {
          display: block;
          background-color: transparent;
        }
      }
      .gt-button {
        margin-top: 32px;
        width: 150px;
      }
      .type {
        color: var(--main-color-ua-dark-grey, #14141e);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
      }
      .desc {
        color: var(--secondary-color-ua-cool-grey-04, #50505a);
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        padding-top: 4px;
      }
    }
  }
  .join-us-customer-service {
    display: flex;
    min-height: 64px;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    background: var(--bg-for-product, #f7f7f7);
    margin-top: 32px;
    p {
      color: var(--main-color-ua-dark-grey, #14141e);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      span {
        margin-right: 12px;
      }
    }
  }
}
</style>
