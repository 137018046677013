<template>
  <div class="ua-personal-center-mb__join-us">
    <div class="ua-personal-center-mb__join-us-wrapper">
      <ul class="join-us-channel">
        <GtSwiper :data="joinUsList" :swiper-option="swiperOption">
          <div slot="default" slot-scope="{ data }" class="swiper-detail">
            <div class="item-wrapper">
              <div class="item-left">
                <div>
                  <p :class="data.icon"></p>
                  <p class="type">{{ data.type }}</p>
                  <p class="desc">{{ data.p }}</p>
                </div>
                <div>
                  <GtButton slot="reference" type="plain" size="mini" @click="buttonClick(data)">
                    {{ data.buttonText }}
                  </GtButton>
                </div>
              </div>
              <div class="swiper-image-container item-right">
                <UAImage
                  style="width: 100%"
                  class="swiper-j_image"
                  :src="data.img"
                  :alt="data.type"
                />
              </div>
            </div>
          </div>
          <div slot="pagination">
            <div class="swiper-pagination"></div>
          </div>
        </GtSwiper>
      </ul>
    </div>

    <div v-if="showService" class="join-us-customer-service">
      <p>如您有任何问题，可咨询我们的客户服务部门</p>
      <p>咨询热线：4008-206-528</p>
      <p>服务时间：周一至周日 09:00-21:00</p>
      <p>邮箱地址：service@underarmour.cn</p>
    </div>

    <van-popup
      v-model="showPopper"
      closeable
      close-icon="close"
      position="bottom"
      class="ua-personal-center-mb__join-us-popper"
    >
      <div class="join-us-popper-content">
        <UAImage :src="curChannel.QrCode" :alt="curChannel.type" style="width: 100%" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { GtButton, GtSwiper } from '@speedshop/template';
import UAImage from '@/components/UAImage/index.vue';
import { mockWindowOpen } from '@/utils/tools.ts';

export default {
  name: 'PersonalCenterJoinUs',
  components: {
    UAImage,
    GtButton,
    GtSwiper
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    showService: {
      type: Boolean,
      default: true
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      curChannel: {},
      showPopper: false
    };
  },
  computed: {
    swiperOption() {
      return {
        spaceBetween: 0,
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination'
        },
        on: {
          resize() {
            this.update(); // 窗口变化时，更新Swiper的一些属性，如宽高等
          }
        }
      };
    },
    joinUsList() {
      return [
        {
          id: '1',
          icon: 'iconfont icon-a-uamp1',
          img: require('@/assets/image/account/channel-m-1.png'),
          QrCode: this.data && this.data.ecMpMiniAppUrl,
          type: '安德玛官方商城',
          p: '微信小程序快捷购物',
          buttonText: '即刻体验',
          to: { name: 'plpSearch', query: { searchWord: '男子装备' } },
          jumpMiniWechat: true
        },
        {
          id: '2',
          icon: 'iconfont icon-a-uahouse',
          img: require('@/assets/image/account/channel-m-2.png'),
          QrCode: this.data && this.data.crmMiniAppUrl,
          type: '安德玛运动家俱乐部',
          p: '解锁更多会员专属福利',
          buttonText: '即刻加入',
          to: { name: 'plpSearch', query: { searchWord: '男子装备' } },
          jumpMiniWechat: true
        },
        {
          id: '3',
          icon: 'iconfont icon-a-web-socialicon-wechat2',
          img: require('@/assets/image/account/channel-m-3.png'),
          QrCode:
            (this.data && this.data.associationUrl) ||
            require('@/assets/image/account/2-sun-mb.png'),
          type: 'UA官方社群',
          p: '种草官方社群',
          buttonText: '即刻加入',
          to: { name: 'plpSearch', query: { searchWord: '男子装备' } }
        }
      ];
    }
  },
  methods: {
    buttonClick(params) {
      this.curChannel = params;
      // if (params.id === '3') {
      //   this.showPopper = true;
      // } else {
        mockWindowOpen(params.QrCode);
      // }
    }
  }
};
</script>

<style lang="scss">
.ua-personal-center__qrcode-wrapper.el-popper {
  background: transparent;
  border: none;
  padding: 0 !important;
  box-shadow: none;
  margin-bottom: -20px !important;
  .gt-image-container {
    background-color: transparent;
  }
}
.ua-personal-center-mb__join-us {
  padding-top: 8px;
  background: var(--bg-for-product, #f7f7f7);
  padding-bottom: 48px;
  overflow: hidden;
  .ua-personal-center-mb__join-us-wrapper {
    width: calc(100% - 40px);
    margin-left: 20px;
    .sp-swiper-container {
      overflow: hidden;
    }
    .swiper-pagination {
      width: calc(100% - 74px);
      margin-top: 10px;
      .swiper-pagination-bullet {
        margin: 0 3px;
        border-radius: 0px;
        width: 4px;
        height: 4px;
      }
      .swiper-pagination-bullet-active {
        width: 16px;
      }
    }
    .sp-swiper-container {
      background-color: #fff;
      .swiper-slide {
        padding: 16px 20px 0px 20px;
      }
    }
    .gt-image-container {
      height: 160px;
      background-color: #fff;
    }
    .item-right {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    .item-left {
      .iconfont {
        color: #dc0019;
        font-size: 24px;
        margin-bottom: 8px;
      }
      .type{
        font-size: 14px;
        font-weight: bold;
        color: var(--main-color-ua-dark-grey, #14141e);
      }
      .title {
        color: var(--main-color-ua-dark-grey, #14141e);
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }
      .desc {
        color: var(--secondary-color-ua-cool-grey-04, #50505a);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
      }
      .gt-button {
        margin-top: 16px;
      }
    }
    .item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .ua-personal-center {
    &__content {
      padding-top: 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
      .qrcode {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        &.show {
          display: block;
          background-color: transparent;
        }
      }
      .gt-button {
        margin-top: 38px;
      }
      .type {
        color: var(--main-color-ua-dark-grey, #14141e);
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
      }
      .desc {
        color: var(--secondary-color-ua-cool-grey-04, #50505a);
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        padding-top: 4px;
      }
    }
  }

  .join-us-customer-service {
    min-height: 64px;
    background: var(--main-color-ua-light-grey, #f0f0f0);
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-top: 40px;
    p {
      color: var(--main-color-ua-dark-grey, #14141e);
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      text-transform: capitalize;
      span {
        margin-right: 12px;
      }
    }
  }
}
</style>
