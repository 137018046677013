var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('gtLandingPage',{ref:"lp",staticClass:"sp-landing-page",attrs:{"priority-wcm-column":true,"list":_vm.newDataList,"iframe-style-config":_vm.iframeStyleConfig,"plp-default-column":3,"data-list-length":_vm.newDataList.length,"data-landing-level":_vm.landingLevel},scopedSlots:_vm._u([{key:"conditionProductList",fn:function(ref){
var data = ref.data;
var operationData = ref.operationData;
return _c('PLP',{attrs:{"page-data":data,"operation-data":operationData}})}},{key:"codeProduct",fn:function(ref){
var data = ref.data;
var layout = ref.layout;
var layoutId = ref.layoutId;
return _c('ProductCode',{attrs:{"data":data,"layout":layout,"layout-id":layoutId}})}},{key:"carousel",fn:function(ref){
var data = ref.data;
return [(data.layoutId === 'widget-swiper-with-recommend-product')?_c('SwiperRecommend',{attrs:{"data":data}}):(
        ['widget-coupon-swiper', 'widget-coupon-template-1'].includes(
          data.content.widgetItem && data.content.widgetItem.widgetCode
        )
      )?_c('SwiperCoupon',{attrs:{"data":data}}):(
        data.content.widgetItem && data.content.widgetItem.widgetCode === 'widget-hot-product'
      )?_c('SwiperHot',{attrs:{"data":data}}):(
        ['widget-collection-selection', 'widget-each-one-is-hard'].includes(data.layoutId)
      )?_c('Swiper',{ref:"menulSwiperRef",staticClass:"each-one-is-hard__side-swiper",attrs:{"data":data,"no-autoplay":true,"no-pagination":true,"no-swiping":true,"no-navigation":true}}):_c('Swiper',{attrs:{"data":data}})]}},{key:"button",fn:function(ref){
      var data = ref.data;
return _c('LPButton',{attrs:{"data":data}})}},{key:"code",fn:function(ref){
      var data = ref.data;
return _c('Code',{attrs:{"data":data}})}},{key:"promotion",fn:function(ref){
      var data = ref.data;
return _c('Promotion',{attrs:{"page-data":data}})}},{key:"text",fn:function(ref){
      var data = ref.data;
return _c('Text1',{attrs:{"data":data}})}},{key:"image",fn:function(ref){
      var data = ref.data;
return _c('Image1',{attrs:{"data":data}})}},{key:"tab",fn:function(ref){
      var data = ref.data;
return _c('Tab',{staticStyle:{"width":"100%"},attrs:{"data":data},on:{"tabSwitch":_vm.onTabSwitch},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var subData = ref.subData;
      var index = ref.index;
return (_vm.defer(10))?[_c('LandingPage',{key:index,attrs:{"column-list":subData.list}})]:undefined}}],null,true)})}},{key:"other",fn:function(ref){
      var data = ref.data;
return [(data.type === 'anchorPoint')?_c('TabAnchor',{attrs:{"data":data},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var subData = ref.subData;
      var index = ref.index;
return [_c('LandingPage',{key:index,attrs:{"column-list":subData.list}})]}}],null,true)}):_c('Other',{attrs:{"data":data}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }