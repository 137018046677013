<template>
  <section ref="container" class="sp-landing-carouselblock carousel-block recommend-product">
    <GtSwiper ref="swiper" :data="newList" :swiper-option="swiperOption">
      <div
        slot="default"
        slot-scope="{ data: subData, index }"
        class="lp-swiper-detail"
        :class="subData.widgetItem && subData.widgetItem.widgetCode"
      >
        <GtLink
          :to="subData.link"
          style="width: 100%"
          :module_name="data.operationData.module_name"
          :module_rank="data.operationData.module_rank"
          :operation_name="data.operationData.operation_name"
          :operation_id="data.operationData.operation_id"
          :operation_rank="data.operationData.operation_rank"
          :destination_url="subData.link"
          click_type="图片"
          expose_type="手动滑动"
          class="operation-img-link"
          target="_blank"
          :click_content="subData.altText"
        >
          <img
            :src="subData.url + '?x-oss-process=image/format,webp'"
            :alt="subData.altText || 'UA banner 图'"
          />

          <!-- <img
            v-else
            :data-src="subData.url + '?x-oss-process=image/format,webp'"
            :alt="subData.altText || 'UA banner 图'"
            class="swiper-lazy-img"
          /> -->
        </GtLink>

        <template v-if="subData.productList && subData.productList.length">
          <div v-if="isMobile" class="second-swiper">
            <div :class="`second-swiper-${index}`">
              <div class="swiper-wrapper" style="display: flex">
                <div
                  v-for="(item, $index) in subData.productList"
                  :key="$index"
                  class="swiper-slide"
                >
                  <nuxt-link
                    :to="{
                      name: 'pdp',
                      params: { code: item.productNo },
                      query: {}
                    }"
                    target="_blank"
                    :module_name="item.operationData && item.operationData.module_name"
                    :module_rank="item.operationData && item.operationData.module_rank"
                    :operation_name="item.operationData && item.operationData.operation_name"
                    :operation_id="item.operationData && item.operationData.operation_id"
                    :operation_rank="item.operationData && item.operationData.operation_rank"
                    :destination_url="item.operationData && item.operationData.destination_url"
                    click_type="商品"
                    :event_name="event"
                    :expose_type="'手动滑动'"
                    :product_rank="item.operationData && item.operationData.product_rank"
                    :primary_category="item.operationData && item.operationData.primary_category"
                    :secondary_category="
                      item.operationData && item.operationData.secondary_category
                    "
                    :third_category="item.operationData && item.operationData.third_category"
                    :activity_attribute="
                      item.operationData && item.operationData.activity_attribute
                    "
                    :gender="item.operationData && item.operationData.gender"
                    :spu_id="item.operationData && item.operationData.spu_id"
                    :spu_name="item.operationData && item.operationData.spu_name"
                    :sales_price="item.operationData && item.operationData.sales_price"
                    :list_price="item.operationData && item.operationData.list_price"
                    :button_name="item.operationData && item.operationData.button_name"
                  >
                    <!-- <Item :data="item"></Item> -->
                    <img :src="(item.mobileMediaLink || item.mediaLink || '').split(',')[0]" />
                    <p class="name">{{ item.shortName || item.productName }}</p>
                    <p class="price">{{ item.salePrice | $PriceFloat }}</p>
                  </nuxt-link>
                </div>
              </div>
              <div
                :class="`second-swiperj-button-next-${index}`"
                class="second-swiperj-button-next"
              >
                <svg
                  width="80"
                  height="21"
                  viewBox="0 0 80 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.12793"
                    width="80"
                    height="20"
                    rx="1"
                    fill="#14141E"
                    fill-opacity="0.45"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M33.0527 4.29989L33.7598 3.59278L40 9.83298L46.2402 3.59278L46.9473 4.29989L40 11.2472L33.0527 4.29989Z"
                    fill="white"
                    fill-opacity="0.45"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M33.0527 10.0694L33.7598 9.36231L40 15.6025L46.2402 9.36231L46.9473 10.0694L40 17.0167L33.0527 10.0694Z"
                    fill="white"
                  />
                </svg>
              </div>
            </div>
          </div>

          <ProductList v-else :data-list="subData.productList" :columns="3" />
        </template>
      </div>
      <template slot="pagination">
        <div v-if="!isMobile">
          <div ref="nextLeft" class="swiperj-button-next swiperj-button-next-re mini">
            <i class="iconfont icon-a-24-2px-arrowright"></i>
          </div>
          <div ref="prevLeft" class="swiperj-button-prev swiperj-button-prev-re mini">
            <i class="iconfont icon-a-24-2px-arrowleft"></i>
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <div v-else>
          <div class="swiper-pagination"></div>
        </div>
      </template>
    </GtSwiper>
  </section>
</template>
<script>
import { GtLink, GtButton, componentsCommon, GtSwiper } from '@speedshop/template';
import Swiper from 'swiper/swiper-bundle.min.js';
import 'swiper/swiper-bundle.css';
import ProductList from './productList.vue';
import { CatalogProduct, CatalogWcmProduct } from '@/utils/product';
import Item from '@/components/PLP/lpItem.vue';

// componentsCommon
export default {
  directives: {},
  components: {
    GtLink,
    GtButton,
    GtSwiper,
    ProductList,
    Item
  },
  mixins: [componentsCommon],
  props: {
    data: {
      type: Object,
      default: () => ({}),
      required: true
    },
    speed: {
      type: Number,
      default: 500
    },
    event: {
      type: String,
      default: 'operationClick'
    }
  },
  data() {
    return {
      rendered: false,
      newList: [],
      productSwiperOption: {
        direction: 'vertical',
        slidesPerView: 'auto',
        slidesPerGroup: 2
      }
    };
  },
  computed: {
    swiperOption() {
      const {
        layoutId,
        content: {
          autoPlay = false,
          Qty = 1,
          timer = 3000,
          bottomButton = true,
          twoSidesButton = true,
          speed = this.speed,
          widgetItem: { widgetCode = layoutId } = {}
        }
      } = this.data || {};
      const slideNumMap = {
        'widget-swiper-slide-1-3': 1.3,
        'widget-swiper-with-recommend-product': this.isMobile ? 1.2 : 1
      };
      const slideGapMap = {
        // 尖货
        'widget-hot-product': this.isMobile ? 12 : 24,
        // 关注我们
        'widget-home-follow-us': 12,
        // 明星产品
        'widget-star-product': this.isMobile ? 12 : 0,
        'widget-swiper-with-recommend-product': this.isMobile ? 12 : 48
      };
      const effectMap = {
        'widget-home-pro': 'fade'
      };
      const loopMap = {
        'widget-star-product': true
      };
      const slidesOffsetAfterMap = {
        'widget-swiper-with-recommend-product': this.isMobile ? 0 : 0
      };
      const slidesPerView = slideNumMap[widgetCode] ? slideNumMap[widgetCode] : Qty;
      const spaceBetween = slideGapMap[widgetCode]
        ? slideGapMap[widgetCode]
        : this.isMobile && slidesPerView > 1
        ? 8
        : slidesPerView === 1
        ? 0
        : 16;
      return {
        effect: effectMap[widgetCode] || 'slide',
        updateOnWindowResize: true,
        speed,
        observer: true, // 开启动态检查器，监测swiper和slide
        observeParents: true, // 监测Swiper 的祖/父元素
        spaceBetween: slideGapMap[widgetCode]
          ? slideGapMap[widgetCode]
          : this.isMobile && slidesPerView > 1
          ? 8
          : slidesPerView === 1
          ? 0
          : 16,

        freeMode: false,

        autoplay: autoPlay
          ? {
              delay: timer,
              disableOnInteraction: true
            }
          : false,
        slidesPerView,
        loop: loopMap[widgetCode],
        loopFillGroupWithBlank: loopMap[widgetCode],
        loopAdditionalSlides: 3,
        breakpoints: {
          1441: {
            spaceBetween: spaceBetween === 48 ? 48 : spaceBetween,
            slidesPerView
          }
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true // 允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: '.swiperj-button-next-re',
          prevEl: '.swiperj-button-prev-re'
        },
        uniqueNavElements: true
      };
    },
    isHotProduct() {
      const {
        content: { widgetItem: { widgetCode } = {} }
      } = this.data || {};
      return widgetCode === 'widget-hot-product';
    }
  },
  async created() {},
  async mounted() {
    this.newList = await this.formatData();
    this.$nextTick(() => {
      this.rendered = true;

      const {
        content: { autoPlay = false }
      } = this.data || {};
      setTimeout(() => {
        const mySwiper = this.$refs.swiper && this.$refs.swiper.swiper;
        if (!mySwiper) return;
        // 鼠标覆盖停止自动切换
        mySwiper.el.onmouseover = function () {
          mySwiper.autoplay && mySwiper.autoplay.stop();
        };

        // 鼠标离开开始自动切换
        mySwiper.el.onmouseout = function () {
          autoPlay && mySwiper.autoplay?.start();
        };
      }, 500);
    });
    setTimeout(() => {
      this.newList.forEach((item, index) => {
        item.swiper = new Swiper(`.second-swiper-${index}`, {
          spaceBetween: 4,
          slidesPerView: 'auto',
          direction: 'vertical',
          autoHeight: true,
          navigation: {
            nextEl: `.second-swiperj-button-next-${index}`
          }
        });
        //
      });
    }, 1000);
  },
  methods: {
    async formatData() {
      const list =
        (this.data && this.data.content && this.data.content.list && this.data.content.list) || [];
      const promiseList = [];
      const newDataList = list.map((item, index) => {
        const { backgroundImage, url, bindProducts, ...others } = item;

        const newItem = {
          ...others,
          bindProducts,
          url: backgroundImage || url,
          fixedImg: backgroundImage ? url : ''
        };
        if (bindProducts && bindProducts.length) {
          promiseList.push(this.getProductData(newItem, index, list));
        }
        return newItem;
      });

      await Promise.all(promiseList);
      return newDataList;
    },
    compare(property) {
      return function (a, b) {
        const value1 = a[property];
        const value2 = b[property];
        return value1 - value2;
      };
    },
    async getProductData(item, index) {
      const list = item.bindProducts;
      if (list.length) {
        const dataList = await this.getPlp(list);
        item.productList = dataList;
      }
    },

    async getPlp(list) {
      const wcmProductReqList = (list || []).map(v => {
        return {
          productCode: v.productCode,
          sellingPoint: v.additionalInfo,
          shortName: v.shortName
        };
      });
      const productCodeList = list.map(i => i.productCode);
      this.rerender = false;
      const data = {
        conditionKeyword: '',
        productCodeList,
        topProductCodeList: [],
        prefetch: true,
        pageSize: 20,
        pageNum: 1,
        hasFilter: this.isDisplayFilter
      };
      console.log(wcmProductReqList, 'wcmProductReqList');
      const Plp = ['home', 'LandingPage', 'LandingPage-all'].includes(this.$route.name)
        ? CatalogWcmProduct({
            isMobile: this.$store.getters.isMobile,
            wcmProductReqList,
            isCache: true
          })
        : CatalogProduct({
            isMobile: this.$store.getters.isMobile,
            wcmProductReqList,
            isCache: true
          });
      const { dataList = [] } = await Plp.init({ ...data, hasFilter: false });
      return dataList;
    }
  }
};
</script>
<style lang="less">
.sp-landing-carouselblock.recommend-product {
  max-width: 100%;
  width: 100%;
  position: relative;
  .swiper-slide {
    width: 100%;
    height: auto;
    max-width: 100% !important;
    position: relative;
    .lp-swiper-detail {
      display: flex;
      align-items: center;
      > span {
        min-width: calc(50% - 24px);
        max-width: calc(50% - 24px);
      }
      .lp-product-list {
        min-width: calc(50% - 24px);
        max-width: calc(50% - 24px);
        flex-grow: 0;
        margin-left: 48px;
      }
      @media (max-width: 1440px) {
        .lp-product-list {
          margin-left: 48px;
        }
      }
    }
    .fixed-container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    > div {
      > span {
        display: block;
        img {
          width: 100%;
          display: block;
        }
      }
      > a {
        display: block;
        img {
          width: 100%;
          display: block;
        }
      }
    }

    .slide-content-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      a {
        width: 100%;
      }
      img {
        width: 100%;
        display: block;
      }
      .cover-title {
        position: absolute;
        top: 10px;
        text-align: center;
        z-index: 2;
      }
    }
  }
  .swiper-wrapper {
    width: 100%;
    .caption {
      padding: 25px 0px 31px 0px;
    }
  }
  .sp-swiper-container {
    overflow: visible;
    .sp-lp-img_btn_link {
      position: absolute;
      .sp-lp-img_btn {
        width: 100% !important;
        height: 100% !important;
        line-height: 1.1 !important;
      }
    }
    .fixed-container_text {
      p {
        position: absolute;
        line-height: 1.2;
      }
    }
  }
  .swiperj-button-prev.mini {
    padding-right: 8px !important;
  }
}

.page-layout_mobile {
  .sp-landing-page > .sp-row:not(.widget-widget-layout-width-fill),
  .el-tab-pane > .sp-landingpage-collection > .sp-row:not(.widget-widget-layout-width-fill) {
    .recommend-product {
      .sp-swiper-container {
        .swiper-slide {
          .lp-swiper-detail {
            > span {
              min-width: 100%;
              max-width: 100%;
            }
          }
          .second-swiper .swiper-slide {
            height: 144px;
            min-height: 144px !important;
          }
        }
      }
    }
  }
  .sp-landing-carouselblock.carousel-block {
    .swiper-pagination {
      bottom: 12px;
    }
    .swiper-slide {
      min-height: 142px;
    }
    .swiper-pagination-bullet {
      width: 4px;
      height: 4px;
      margin: 4px;

      &.swiper-pagination-bullet-active {
        width: 16px;
        height: 4px;
      }
    }
  }
  .swiperj-button-prev,
  .swiperj-button-next {
    font-size: 24px;
    color: #14141e;
    top: 40.5vw;
    &.swiper-button-disabled {
      color: #b4b4bf;
      display: none;
    }
  }
  .swiperj-button-prev {
    left: 20px;
  }
  .swiperj-button-next {
    right: 20px;
  }
}
.sp-landing-carouselblock.carousel-block {
  .swiper-pagination {
    bottom: 20px;
  }
  .swiper-pagination-bullet {
    background: var(--white-45, rgba(255, 255, 255, 0.45));
    width: 6px;
    height: 6px;
    margin: 6px;
    border-radius: 0px;
    opacity: 1;
    margin-bottom: 0px;

    &.swiper-pagination-bullet-active {
      width: 32px;
      height: 6px;
      background: var(--main-color-ua-white, #fff);
    }
  }
}
</style>
