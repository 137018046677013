<template>
  <section ref="imgBlock" class="sp-landing-imageblock image-block">
    <p
      v-if="data.content.desPosition === 'top' && data.content.description"
      class="sp-landing-imageblock__text text-top"
    >
      <GtLink
        :to="data.content.link"
        :module_name="data.operationData.module_name"
        :module_rank="data.operationData.module_rank"
        :operation_name="data.operationData.operation_name"
        :operation_id="data.operationData.operation_id"
        :operation_rank="data.operationData.operation_rank"
        :destination_url="data.content.link"
        click_type="文案"
        expose_type="手动滑动"
        class="operation-img-link"
        :click_content="data.content.description"
        :target="data.content.link && data.content.link.includes('/login') ? '' : '_blank'"
      >
        {{ data.content.description }}
      </GtLink>
    </p>
    <div class="sp-landing-imageblock__img-w">
      <p
        v-if="data.content.desPosition === 'cover' && data.content.description"
        class="sp-landing-imageblock__text text-cover"
      >
        <GtLink
          v-if="data.content.link"
          :to="data.content.link"
          :module_name="data.operationData.module_name"
          :module_rank="data.operationData.module_rank"
          :operation_name="data.operationData.operation_name"
          :operation_id="data.operationData.operation_id"
          :operation_rank="data.operationData.operation_rank"
          :destination_url="data.content.link"
          click_type="文案"
          expose_type="手动滑动"
          class="operation-img-link"
          :target="data.content.link && data.content.link.includes('/login') ? '' : '_blank'"
          :click_content="data.content.description"
        >
          {{ data.content.description }}
        </GtLink>
      </p>
      <a
        v-if="isExternalLink"
        :module_name="data.operationData.module_name"
        :module_rank="data.operationData.module_rank"
        :operation_name="data.operationData.operation_name"
        :operation_id="data.operationData.operation_id"
        :operation_rank="data.operationData.operation_rank"
        :destination_url="data.content.link"
        click_type="图片"
        expose_type="手动滑动"
        class="operation-img-link"
        :class="{ 'operation-img-no-jump': !data.content.link }"
        :target="data.content.link && data.content.link.includes('/login') ? '' : '_blank'"
        :click_content="data.content.altText"
        :href="data.content.link || '#'"
      >
        <UAImage
          v-if="data.layoutId === 'layout-first-module'"
          style="width: 100%"
          :alt="data.content.altText || 'operation img'"
          :src="data.content.url"
        />
        <UAImage
          v-else
          :src="data.content.url"
          :alt="data.content.altText || 'operation img'"
          style="width: 100%"
          class="swiper-lazy-img"
        />
      </a>
      <nuxt-link
        v-else
        :to="data.content.link || '#'"
        :module_name="data.operationData.module_name"
        :module_rank="data.operationData.module_rank"
        :operation_name="data.operationData.operation_name"
        :operation_id="data.operationData.operation_id"
        :operation_rank="data.operationData.operation_rank"
        :destination_url="data.content.link"
        click_type="图片"
        expose_type="手动滑动"
        class="operation-img-link"
        :class="{ 'operation-img-no-jump': !data.content.link }"
        :target="data.content.link && data.content.link.includes('/login') ? '' : '_blank'"
        :click_content="data.content.altText"
      >
        <UAImage
          v-if="data.layoutId === 'layout-first-module'"
          style="width: 100%"
          :alt="data.content.altText || 'operation img'"
          :src="data.content.url"
        />
        <UAImage
          v-else
          :src="data.content.url"
          :alt="data.content.altText || 'operation img'"
          style="width: 100%"
          class="swiper-lazy-img"
        />
      </nuxt-link>
    </div>
    <p
      v-if="data.content.desPosition === 'bottom' && data.content.description"
      class="sp-landing-imageblock__text text-bottom"
    >
      <GtLink
        :to="data.content.link"
        :module_name="data.operationData.module_name"
        :module_rank="data.operationData.module_rank"
        :operation_name="data.operationData.operation_name"
        :operation_id="data.operationData.operation_id"
        :operation_rank="data.operationData.operation_rank"
        :destination_url="data.content.link"
        click_type="文案"
        expose_type="手动滑动"
        class="operation-img-link"
        :target="data.content.link && data.content.link.includes('/login') ? '' : '_blank'"
        :click_content="data.content.description"
      >
        {{ data.content.description }}
      </GtLink>
    </p>
  </section>
</template>
<script>
import { GtLink, componentsCommon } from '@speedshop/template';
import UAImage from '@/components/UAImage/index.vue';

export default {
  components: {
    UAImage,
    GtLink
  },
  mixins: [componentsCommon],

  props: {
    data: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  data() {
    return {
      rendered: false
    };
  },
  computed: {
    isExternalLink() {
      return (
        typeof this.data.content.link === 'string' &&
        (this.data.content.link.startsWith('http') || this.data.content.link.startsWith('https'))
      );
    }
  },
  mounted() {
    // this.init()
    // this.$nextTick(() => {
    //   this.rendered = true;
    // });
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.iswebp = true;
        // document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0;
      });
    }
  }
};
</script>
<style lang="less">
.operation-img-no-jump {
  pointer-events: none;
}
.sp-landing-imageblock {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  width: 100%;
  max-width: 100%;
  &__img-w {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 100%;
    a {
      display: grid;
      width: 100%;
    }
    .el-image__inner {
      width: 100%;
    }
  }
  &__text {
    padding: var(--landingpage-image-widget-text-padding);
    font-weight: var(--landingpage-image-widget-text-font-weight);
    text-align: var(--landingpage-image-widget-text-align);
    font-size: var(--landingpage-image-widget-text-font-size);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: var(--landingpage-image-widget-text-webkit-line-clamp);
    -webkit-box-orient: vertical;

    &.text-cover {
      position: absolute;
      z-index: 1;
      bottom: 10px;
    }
  }
}
</style>
<style lang="less">
.page-layout_mobile {
  .sp-landing-imageblock {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    &__img-w {
      position: relative;
      a {
        display: grid;
      }
    }
    &__text {
      font-weight: 600 !important;
      &.text-cover {
        position: absolute;
        z-index: 1;
        bottom: 10px;
      }
    }
  }
}
</style>
