<template>
  <section ref="newProductSwiper" class="sp-landing-carouselblock carousel-block coupon-swiper">
    <GtSwiper ref="swiper" :data="newList" :swiper-option="swiperOption">
      <div
        slot="default"
        slot-scope="{ data: subData, index }"
        :v-expose-track="exposeTrack(subData)"
        :class="subData.widgetItem && subData.widgetItem.widgetCode"
        @click="handleGetCoupon(subData)"
      >
        <template v-if="isTemplate">
          <section class="coupon-template-default">
            <div class="coupon-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8"
                height="151"
                viewBox="0 0 8 151"
                fill="none"
              >
                <circle cx="4" cy="15" r="4" fill="#DC0019" />
                <circle cx="4" cy="4" r="4" fill="#DC0019" />
                <circle cx="4" cy="26" r="4" fill="#DC0019" />
                <circle cx="4" cy="37" r="4" fill="#DC0019" />
                <circle cx="4" cy="48" r="4" fill="#DC0019" />
                <circle cx="4" cy="59" r="4" fill="#DC0019" />
                <circle cx="4" cy="70" r="4" fill="#DC0019" />
                <circle cx="4" cy="81" r="4" fill="#DC0019" />
                <circle cx="4" cy="92" r="4" fill="#DC0019" />
                <circle cx="4" cy="103" r="4" fill="#DC0019" />
                <circle cx="4" cy="125" r="4" fill="#DC0019" />
                <circle cx="4" cy="136" r="4" fill="#DC0019" />
                <circle cx="4" cy="147" r="4" fill="#DC0019" />
                <circle cx="4" cy="114" r="4" fill="#DC0019" />
              </svg>
              <h3>
                {{ subData.textList[0] && subData.textList[0].textDesc }}
              </h3>
              <p>
                {{ subData.textList[1] && subData.textList[1].textDesc }}
              </p>
            </div>
            <div class="coupon-center">
              <h4>{{ subData.textList[2] && subData.textList[2].textDesc }}</h4>
              <p>{{ subData.textList[3] && subData.textList[3].textDesc }}</p>
            </div>
            <div class="coupon-right">
              <GtButton type="primary" size="small">立即领取</GtButton>
            </div>
          </section>
        </template>
        <template v-else>
          <img
            :src="subData.url + '?x-oss-process=image/format,webp'"
            :alt="subData.altText || 'UA banner 图'"
            class="swiper-lazy-img"
          />

          <div v-if="subData.buttonList && subData.buttonList.length">
            <GtButton
              v-for="(btn, i) in subData.buttonList"
              :key="i"
              class="sp-lp-img_btn"
              :style="{
                top: `${btn.percentageTop}%`,
                left: `${btn.percentageLeft}%`,
                width: `${btn.btnPercentageWidth}%`,
                height: `${btn.height}px`,
                lineHeight: `${btn.height}px`
              }"
            >
              {{ btn.text }}
            </GtButton>
          </div>
        </template>
      </div>
      <template slot="pagination">
        <div></div>
      </template>
    </GtSwiper>
  </section>
</template>
<script>
import { GtLink, GtButton, componentsCommon, GtSwiper, GtMessage } from '@speedshop/template';
import { useBasic } from '@speedshop/sdk-api';
import { uaSendCouponToUsers, findExtActivity } from '@/graphql/lp.gql';
// componentsCommon
export default {
  directives: {},
  components: {
    GtLink,
    GtButton,
    GtSwiper
  },
  mixins: [componentsCommon],
  props: {
    data: {
      type: Object,
      default: () => ({}),
      required: true
    },
    speed: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      rendered: false,
      dataList: [],
      newList: []
    };
  },
  computed: {
    isTemplate() {
      const {
        content: { widgetItem: { widgetCode } = {} }
      } = this.data || {};
      return ['widget-coupon-template-1'].includes(widgetCode);
    },
    swiperOption() {
      const {
        layoutId,
        content: {
          autoPlay = false,
          Qty = 1,
          timer = 3000,
          bottomButton = true,
          twoSidesButton = true,
          speed = this.speed,
          widgetItem: { widgetCode = layoutId } = {}
        }
      } = this.data || {};
      return {
        slidesPerView: Qty,
        observer: true,
        observeParents: true,
        spaceBetween: this.isMobile ? 8 : 48,
        freeMode: true,
        loop: false,
        allowSlidePrev: false,
        allowSlideNext: false,
        uniqueNavElements: false
      };
    }
  },
  created() {
    const list =
      (this.data && this.data.content && this.data.content.list && this.data.content.list) || [];
    const newList = list.map(item => {
      return {
        ...item,
        couponDetail: {}
      };
    });
    this.newList = newList;
  },
  mounted() {},
  methods: {
    async queryAllDetail() {
      const list =
        (this.data && this.data.content && this.data.content.list && this.data.content.list) || [];
      const fetchList = [];
      const newList = list.map(item => {
        fetchList.push(
          (async () => {
            const data = await this.queryCouponDetail(item.link);
            item.couponDetail = data;
          })()
        );
      });
      await Promise.all(fetchList);
      this.dataList = newList;
    },
    async queryCouponDetail(code) {
      // findExtActivity
      const Basic = useBasic();
      const { data } = await Basic.gqlClient.query(findExtActivity, {
        activityCode: code
      });
      return data;
    },
    async exposeTrack(item) {
      const { data } = await this.queryCouponDetail(item.link);
      item.couponDetail = data;
      this.$trackHelp.track('couponExpose', {
        belong_page: this.$route.meta.title,
        coupon_name: data.activityName,
        coupon_id: data.activityCode,
        coupon_amount: data.couponAmount || 0
      });
    },
    handleGetCoupon(item) {
      if (this.isGuest || !this.isLogin) {
        this.$eventBus.$on('drawLoginSuccessCallback', this.getCoupon.bind(this, item));
        this.$eventBus.$emit('LoginShow', { showLogin: true, showGuest: false, refresh: false });
      } else {
        this.getCoupon(item);
      }

      // uaSendCouponToUsers
    },
    async getCoupon(item) {
      const Basic = useBasic();
      await Basic.gqlClient.query(uaSendCouponToUsers, {
        input: {
          activityCode: item.link,
          qty: 1
        }
      });
      GtMessage.success('领取成功');
      const data = item.couponDetail;
      this.$trackHelp.track('couponReceive', {
        belong_page: this.$route.meta.title,
        coupon_name: data.activityName,
        coupon_id: data.activityCode,
        coupon_amount: data.couponAmount
      });
    }
  }
};
</script>
<style lang="less">
.coupon-swiper {
  img {
    width: 100%;
    cursor: pointer;
  }
  .coupon-template-default {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-radius: 4px;
    background: var(--Main-Color-UA-red, #dc0019);
    padding: 8px 0px 8px 8px;

    .coupon-left {
      padding: 0px 34px;
      min-width: 167px;
      background: #fff;
      height: 152px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      border-right: 1px dashed #ddd;
      position: relative;
      svg {
        position: absolute;
        left: -5px;
        top: 0px;
        bottom: 0px;
      }
      &::before {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        border-radius: 100%;
        background: var(--Main-Color-UA-red, #dc0019);
        right: -6px;
        top: -6px;
      }
      &::after {
        content: '';
        width: 12px;
        height: 12px;
        position: absolute;
        border-radius: 100%;
        background: var(--Main-Color-UA-red, #dc0019);
        right: -6px;
        bottom: -6px;
      }
      h3 {
        color: var(--Main-Color-UA-dark-grey, #14141e);
        leading-trim: both;
        text-edge: cap;

        /* cn/pingfang/bold/64 */
        font-size: 64px;
        font-weight: 600;
        line-height: 70px;
      }
      @media (max-width: 1440px) {
        h3 {
          font-size: 48px;
        }
      }
      p {
        color: var(--Main-Color-UA-dark-grey, #14141e);
        text-align: center;

        /* cn/pingfang/regular/16 */
        font-size: 16px;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
      }
    }
    .coupon-center {
      background: #fff;
      flex-grow: 1;
      height: 152px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0px 32px;

      h4 {
        color: var(--Main-Color-UA-dark-grey, #14141e);

        /* cn/pingfang/bold/24 */
        font-size: 24px;
        font-weight: 600;
        line-height: 30px; /* 125% */
      }

      p {
        color: var(--Secondary-Color-UA-cool-grey-04, #50505a);

        /* cn/pingfang/regular/16 */
        font-size: 16px;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
      }
      @media (max-width: 1440px) {
        h4 {
          font-size: 20px;
        }
        p {
          font-size: 14px;
        }
      }
    }
    .coupon-right {
      width: 185px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      .gt-button {
        width: 120px;
      }
    }
  }
}
.page-layout_mobile {
  .sp-landing-carouselblock.carousel-block {
    .swiper-slide {
      min-height: 88px;
    }
  }
  .coupon-swiper {
    .coupon-template-default {
      .coupon-left {
        padding: 0px 8px;
        min-width: 80px;
        background: #fff;
        height: 80px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        border-right: 1px dashed #ddd;
        position: relative;
        svg {
          position: absolute;
          left: -5px;
          top: 0px;
          bottom: 0px;
        }
        &::before {
          content: '';
          width: 12px;
          height: 12px;
          position: absolute;
          border-radius: 100%;
          background: var(--Main-Color-UA-red, #dc0019);
          right: -6px;
          top: -6px;
        }
        &::after {
          content: '';
          width: 12px;
          height: 12px;
          position: absolute;
          border-radius: 100%;
          background: var(--Main-Color-UA-red, #dc0019);
          right: -6px;
          bottom: -6px;
        }
        h3 {
          color: var(--Main-Color-UA-dark-grey, #14141e);
          leading-trim: both;
          text-edge: cap;

          /* cn/pingfang/bold/64 */
          font-size: 36px;
          font-weight: 600;
          line-height: 36px;
        }

        p {
          color: var(--Main-Color-UA-dark-grey, #14141e);
          text-align: center;

          /* cn/pingfang/regular/16 */
          font-size: 12px;
          font-weight: 400;
          line-height: 18px; /* 137.5% */
        }
      }
      .coupon-center {
        background: #fff;
        flex-grow: 1;
        height: 80px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding: 0px 8px;

        h4 {
          color: var(--Main-Color-UA-dark-grey, #14141e);

          /* cn/pingfang/bold/24 */
          font-size: 14px;
          font-weight: 600;
          line-height: 20px; /* 125% */
        }

        p {
          color: var(--Secondary-Color-UA-cool-grey-04, #50505a);

          /* cn/pingfang/regular/16 */
          font-size: 12px;
          font-weight: 400;
          line-height: 16px; /* 137.5% */
        }
      }
      .coupon-right {
        width: 96px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        .gt-button {
          width: 72px !important;
        }
      }
    }
  }
}
</style>
