<template>
  <div ref="reference" :class="['sp-landing-swiper-tab']" style="width: 100%">
    <Tabs
      v-if="tabList && tabList.length"
      ref="tabHeaderRef"
      v-model="activeValue"
      :stretch="false"
      :class="['gt-tabs', { 'is-sticky': isSticky }]"
      :style="`top: ${stickyTop}px`"
      @tab-click="handleClick"
    >
      <TabPane
        v-for="(item, i) in data.couselList"
        :key="i"
        :label="item.title"
        :name="item.url"
        :lazy="false"
        :data-url="item.url"
      >
        <template slot="label">
          <div v-if="item.unselectBackground && item.selectBackground" class="tab-img-box">
            <img
              class="default-img"
              :src="item.unselectBackground + '?x-oss-process=image/format,webp'"
              alt=""
            />
            <img
              class="selected-img"
              :src="item.selectBackground + '?x-oss-process=image/format,webp'"
              alt=""
            />
          </div>

          <span v-else>{{ item.title }}</span>
        </template>
      </TabPane>
    </Tabs>
    <!-- .swiper-no-swiping用来阻止滑动的 -->
    <div v-if="defer(1)" ref="contentRef" class="sp-landing-tab-content">
      <GtSwiper
        ref="gtSwiperRef"
        :class="['sp-landing-tab-swipper', { 'swiper-no-swiping': !isMobile }]"
        :data="tabList"
        :swiper-option="swiperOption"
        :hidden-button="true"
      >
        <template slot="default" slot-scope="{ data: subData, index }">
          <div
            v-if="isMobile ? subData.url === activeValue : true"
            v-dom-hook="resizeOption"
            class="sp-landing-tab-swipper-item"
          >
            <slot v-if="defer(10)" :subData="subData" :index="index"></slot>
          </div>
        </template>
      </GtSwiper>
    </div>
  </div>
</template>

<script>
import { useBasic, useLandingPage } from '@speedshop/sdk-api';
import { componentsCommon, GtSwiper } from '@speedshop/template';
import { Tabs, TabPane } from 'element-ui';
import { throttle } from 'lodash';
import { getScrollTop, scrollToTopW } from '@/utils/dom';
import defer from '@/mixins/defer';

export default {
  components: {
    Tabs,
    TabPane,
    GtSwiper
  },
  // directives: {
  //   observeResize: {
  //     bind(el, binding, vnode) {
  //       const vm = vnode.context;
  //       vm.observeResizeHandle(el);
  //     },
  //     unbind(el, binding, vnode) {
  //       const vm = vnode.context;
  //       vm.resizeObserver.disconnect();
  //     }
  //   }
  // },
  mixins: [componentsCommon, defer()],
  inject: {
    landingLevel: { default: 1 }
  },
  props: {
    data: {
      type: Object,
      requirede: true
    }
  },
  data() {
    return {
      activeValue: this.data.couselList && this.data.couselList[0].url,
      fetched: false,
      tabList: [],
      reloadAnchorRefs: [],
      reloadAnchorObserve: undefined,
      resizeObserver: undefined,
      stickyTop: 94,
      resizeOption: {
        inserted: el => {
          this.observeResizeHandle(el);
        },
        unbind: () => {
          this.resizeObserver?.disconnect?.();
        }
      }
    };
  },
  computed: {
    isIndexBigTab() {
      return this.$route.name === 'home' && this.landingLevel === 1;
    },
    isSticky() {
      return this.data?.content?.widgetItem?.widgetCode === 'widget-tab-sticky-to-top';
    },
    operationData() {
      return this.data && this.data.operationData;
    },
    swiperOption() {
      const self = this;
      return {
        slidesPerView: 1,
        autoHeight: true,
        observer: true,
        observeParents: true,
        // observeSlideChildren: true,
        spaceBetween: (this.isMobile ? 8 : 48) * 2,
        // autoplay: false,
        // loop: true,
        // allowSlidePrev: false,
        // allowSlideNext: false,
        // uniqueNavElements: false,
        // pagination: false,
        // navigation: false,
        on: {
          slideChangeTransitionEnd() {
            const item = self.data.couselList[this.activeIndex];
            self.activeValue = item.url;
            self.$emit('tabSwitch', this.activeIndex, self.$refs.reference);
            self.tabBackToTop();
          }
        }
      };
    }
  },
  // created() {
  // },
  mounted() {
    // const lazyHandleScroll = throttle(this.handleScroll, 1000);
    // window.addEventListener('scroll', lazyHandleScroll, true);
    // this.$on('hook:beforeDestroy', () => {
    //   window.removeEventListener('scroll', lazyHandleScroll, true);
    // });
    this.getTabData();
    this.resetTop();

    this.$eventBus.$on('close-mobile-marquree', () => {
      this.resetTop();
    });
  },
  // destroyed() {
  //   window.removeEventListener('scroll', this.scrollHandle);
  // },
  methods: {
    resetTop() {
      const H =
        document.querySelector('.mobile_header-layer')?.getBoundingClientRect?.().height || 94;
      this.stickyTop = H;
    },
    scrollHandle(e) {
      // const scrollDom = document.querySelector('html');
      // const container = this.$refs.contentRef;
      // console.log('updateSize', getScrollTop(), container.getBoundingClientRect().top);
      // if (container.getBoundingClientRect().top) {
      // }
      // this.$refs.gtSwiperRef?.swiper?.update();
    },
    updateSwiper() {},
    async getTabData() {
      const LandingPage = useLandingPage();
      const list = (this.data && this.data.couselList) || [];

      const fetchList = list.map(async item => {
        const { landingData } = await LandingPage.init({
          pageUrl: item.pageUrl,
          deviceType: this.isMobile ? 'h5' : 'pc',
          preview: this.$route.query.preview === 'true',
          cacheFlag: this.$route.query.preview !== 'true'
        });
        item.list = landingData;
      });
      await Promise.all(fetchList);
      this.fetched = true;
      this.tabList = list;
      // setTimeout(() => {
      //   this.lazyLoad();
      // }, 500);
    },
    // handleHardClick() {},
    handleClick({ name }) {
      const index = this.data.couselList.findIndex(i => i.url === name);
      // this.choiceIndex = index;
      this.$refs.gtSwiperRef?.swiper?.slideTo(index, 500, true);
      // const item = this.data.couselList[index];
    },
    tabBackToTop() {
      const widgetCode = this.data.content?.widgetItem?.widgetCode;
      if (widgetCode === 'widget-tab-sticky-to-top') {
        // const tab = item.target;
        const tabHeader = this.$refs.tabHeaderRef?.$el;
        // console.log(tabHeader, 'tabHeader');

        const boundingData = tabHeader?.getBoundingClientRect?.();
        // console.log(tab, 'tab');
        // const header = this.isMobile
        //   ? document.querySelector('.mobile_header-layer')
        //   : document.querySelector('.gt-header ');
        this.$nextTick(() => {
          // console.log(1);
          const $container = tabHeader.parentElement;
          if (!$container) return;
          const noAd = document.querySelector('.gt-layout-without-ad');
          // console.log(2);
          const stickyTop = this.isMobile && noAd ? 54 : this.isMobile ? 94 : 99;
          // console.log(3);
          if (boundingData.top > stickyTop) {
            // console.log(4, boundingData.top, stickyTop);
            // this.$nextTick(() => {
            //   this.lazyLoad();
            // });

            return;
          }
          // console.log(5);
          // console.log(window.scrollY, '$container.offsetTop');
          // console.log($container.getBoundingClientRect().top, 'tabHeader.clientHeight');
          // console.log(header.clientHeight, 'header.clientHeight');
          const headerMarginBottom = 20;
          const Top =
            window.scrollY +
            $container.getBoundingClientRect().top -
            stickyTop +
            headerMarginBottom;

          scrollToTopW(Top);
        });
      } else {
        // this.$nextTick(() => {
        //   this.lazyLoad();
        // });
      }
    },
    // updateSwiperSize: throttle(
    //   () => {
    //     this.$refs.gtSwiperRef?.swiper?.updateAutoHeight();
    //   },
    //   50,
    //   { leading: true, trailing: true }
    // ),
    observeResizeHandle(el) {
      if (!el) return;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(() => {
          requestAnimationFrame(async () => {
            // this.updateSwiperSize();
            await this.$nextTick();
            this.$refs.gtSwiperRef?.swiper?.updateAutoHeight();
          });
        });
      }
      this.resizeObserver.observe(el);
    }
    // lazyLoad() {
    //   const images = document.querySelectorAll('img[data-src]');
    //   images.forEach(image => {
    //     if (this.isInViewport(image)) {
    //       image.src = image.getAttribute('data-src');
    //       image.removeAttribute('data-src');
    //     }
    //   });
    // },
    // isInViewport(element) {
    //   const rect = element.getBoundingClientRect();

    //   return (
    //     rect.bottom > 0 &&
    //     rect.top < (window.innerHeight || document.documentElement?.clientHeight) + 200
    //   );
    // }
  }
};
</script>

<style lang="scss" scoped>
.sp-landing-swiper-tab {
  // .gt-tabs {
  //   &.is-sticky {
  //     background: #fff;
  //     position: sticky;
  //     top: 99px;
  //     z-index: 101;
  //   }
  //   &.home-category-lp-shadow {
  //     box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  //   }
  // }
}
.sp-landing-tab-content {
  overflow: hidden;
}
</style>
<style lang="scss">
// 修复tab下面的sticky
.page-layout_pc
  .page-home
  > .sp-landing-page[data-landing-level='1']
  + .home-category-lp
  > .sp-landing-swiper-tab
  > .sp-landing-tab-content {
  overflow: visible !important;
}
.sp-landing-tab-swipper > .swiper-wrapper > .swiper-slide {
  overflow: visible;
}
.sp-landing-tab-content .sp-landingpage-collection[data-landing-level='3'] > .sp-row,
.sp-landing-tab-content .sp-landingpage-collection[data-landing-level='4'] > .sp-row,
.sp-landing-tab-content .sp-landingpage-collection[data-landing-level='5'] > .sp-row {
  margin: 0 auto;
  padding: unset;
}
</style>
